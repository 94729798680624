//// LOCAL
import './mtska-frontend-app-scaffolding/styles/App/App.scss';
import { App } from './mtska-frontend-app-scaffolding';
import { EmptyModule, RoleModule, PersonModule, BiModule, LegalEntityModule, BiViewerModule, AuthModule } from './mtska-frontend-modules';
import ActivatorProvider from './mtska-frontend-modules/context/ActivatorContext';

//// PROD
// import '@BluedogSrl/mtska-frontend-app-scaffolding/dist/ems/index.css';
// import { App,  NoModule } from '@BluedogSrl/mtska-frontend-app-scaffolding';
// import { EmptyModule, RoleModule, PersonModule } from '@BluedogSrl/mtska-frontend-modules';


const AppExecutive = () => {
    const config = {
        title: process.env.REACT_APP_SHORT_NAME,
        modules: [
            AuthModule,
            BiViewerModule
        ],
        copyright: '© MyFleetSolution',
        privacy: {
            label: 'Privacy policy',
            url: 'https://www.myfleetsolution.it'
        },
        auth0: {
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            clientId: process.env.REACT_APP_AUTH0_CLIENTID,
            authorizationParams: {
                redirect_uri: process.env.REACT_APP_AUTH0_AUTHORIZATIONPARAMS_REDIRECT_URI,
            }
        }
    }

    async function checkInvalidate() {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/api/invalidate');
        response.text().then(function (text) {
            const apiInvalidate = text;
            const lsInvalidate = localStorage.getItem('invalidate');
            if (lsInvalidate == null || apiInvalidate > lsInvalidate) {
                
                localStorage.clear();
                localStorage.setItem('invalidate', apiInvalidate);
            }
        });



    }
    checkInvalidate();



    return (
        <ActivatorProvider>
            <App concreteconfig={config} />
        </ActivatorProvider>
    )
}

export default AppExecutive;
